import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

const BookingOverview = ({ classes, stockTakeDetails, loading }) => {
  const theme = useTheme();

  // Define columns configuration
  const columns = [
    {
      id: 'service',
      label: 'Service',
      align: 'left',
    },
    {
      id: 'item',
      label: 'Item',
      align: 'left',
    },
    {
      id: 'serviceUsed',
      label: 'No. of Service Used',
      align: 'center',
    },
    {
      id: 'pumpsUsed',
      label: 'Total Pumps used',
      align: 'center',
    },
  ];

  // Get cell content based on column id
  const getCellContent = (booking, stock, columnId, isFirstRow) => {
    const mapping = {
      service: isFirstRow ? booking.serviceName || 'Bulk' : null,
      item: stock.inventoryName,
      serviceUsed: stock.serviceUsed,
      pumpsUsed: stock.numOfPumps,
    };
    return mapping[columnId];
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" style={{ marginTop: '16px' }}>
        Booking Overview
      </Typography>
      <Box style={{ marginTop: '24px', marginBottom: '24px' }}>
        <Typography variant="body2" component="span">
          Total No of Completed Bookings:
        </Typography>{' '}
        <Typography variant="h6" component="span">
          {stockTakeDetails?.numOfBookings ?? 0}
        </Typography>
      </Box>
      <Table style={{ minHeight: '100px', maxWidth: '1024px' }}>
        <TableHead>
          <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                className={clsx(classes.header, column.id !== 'pumpsUsed' && classes.borderRight)}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stockTakeDetails?.usedByServices?.length > 0 ? (
            stockTakeDetails.usedByServices.map(booking =>
              booking.stockDetails.map((stock, index) => (
                <TableRow key={`${booking.appID}-${booking.companyID}-${stock.inventoryID}`}>
                  {columns.map(column => {
                    const isFirstRow = index === 0;
                    const content = getCellContent(booking, stock, column.id, isFirstRow);

                    // Skip cell rendering for service column if not first row
                    if (column.id === 'service' && !isFirstRow) {
                      return null;
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={clsx(column.id !== 'pumpsUsed' && classes.borderRight)}
                        {...(column.id === 'service' && { rowSpan: booking.stockDetails.length })}>
                        {content}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )),
            )
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    height: '50px',
                  }}>
                  {loading ? <CircularProgress size={30} /> : <Typography variant="h4">No Booking found</Typography>}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default BookingOverview;
