import React, { useEffect, useState } from 'react';
import { IconButton, makeStyles, Box, Dialog, DialogTitle, DialogContent, useTheme, Snackbar } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import StockTakeInfo from './StockTakeInfo';
import BookingOverview from './BookingOverview';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px auto',
    padding: theme.spacing(2),
  },
  date: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  header: {
    color: theme.palette.common.white,
  },
  borderRight: {
    borderRight: `1px solid #fff`,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StockTakeDetailsDialog = props => {
  const { memberID, stockTakeID, stockTakeDate } = props;

  const classes = useStyles();

  const [stockTakeDetails, setStockTakeDetails] = useState([]);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const theme = useTheme();

  const getStockTakeDetails = () => {
    setLoading(true);

    httpClient.get(`member/${memberID}/stocktake/${stockTakeID}`).then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setStockTakeDetails(data.data);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(data.message);
        setMessageState('error');

        setTimeout(() => {
          props.onClose(); // close the modal
        }, 2000);

        return;
      }
    });
  };

  useEffect(() => {
    getStockTakeDetails();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog open={true} onClose={() => props.onClose()} maxWidth="xl">
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle>
            <h2>Stock Take date and other details</h2>
          </DialogTitle>
          <IconButton onClick={props.onClose} className={classes.closeBtn}>
            <Close style={{ color: theme.palette.error.main }} />
          </IconButton>
        </Box>
        <DialogContent className={classes.content}>
          <StockTakeInfo
            classes={classes}
            stockTakeDate={stockTakeDate}
            stockTakeDetails={stockTakeDetails}
            loading={loading}
          />
          <BookingOverview classes={classes} stockTakeDetails={stockTakeDetails} loading={loading} />
        </DialogContent>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StockTakeDetailsDialog;
