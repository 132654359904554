import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Chip,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import { formatPercentage } from '@jumbo/utils/commonHelper';

const StockTakeInfo = ({ classes, stockTakeDate, stockTakeDetails, loading }) => {
  const theme = useTheme();

  const headerColumns = [
    { id: 'item', label: 'Item', colSpan: 2, rowSpan: 2 },
    { id: 'stockTake', label: 'Stock Take', colSpan: 2 },
    { id: 'purchased', label: 'Purchased', colSpan: 2 },
    { id: 'total', label: 'Total', colSpan: 2 },
    { id: 'used', label: 'Used', colSpan: 2 },
    { id: 'remaining', label: 'Remaining', colSpan: 2 },
  ];

  const subHeaderColumns = [
    { id: 'stockTakeBottles', label: '(in bottles)', parentId: 'stockTake', align: 'center', borderRight: false },
    { id: 'stockTakePumps', label: '(in pumps)', parentId: 'stockTake', align: 'center', borderRight: true },
    { id: 'purchasedBottles', label: '(in bottles)', parentId: 'purchased', align: 'center', borderRight: false },
    { id: 'purchasedPumps', label: '(in pumps)', parentId: 'purchased', align: 'center', borderRight: true },
    { id: 'totalBottles', label: '(in bottles)', parentId: 'total', align: 'center', borderRight: false },
    { id: 'totalPumps', label: '(in pumps)', parentId: 'total', align: 'center', borderRight: true },
    { id: 'usedBottles', label: '(in bottles)', parentId: 'used', align: 'center', borderRight: false },
    { id: 'usedPumps', label: '(in pumps)', parentId: 'used', align: 'center', borderRight: true },
    { id: 'remainingBottles', label: '(in bottles)', parentId: 'remaining', align: 'center', borderRight: false },
    { id: 'remainingPumps', label: '(in pumps)', parentId: 'remaining', align: 'center', borderRight: true },
  ];

  // Cell content mapping
  const getCellContent = (item, columnId) => {
    const mapping = {
      stockTakeBottles: item.stockTakeBottle,
      stockTakePumps: item.stockTakeInPumps,
      purchasedBottles: item.purchasedBottle,
      purchasedPumps: item.purchasedInPumps,
      totalBottles: item.stockTakeBottle + item.purchasedBottle,
      totalPumps: item.totalStocksInPumps,
      usedBottles: item.stockUsagesBottle,
      usedPumps: item.stockUsagesInPumps,
      remainingBottles: item.remainingStockBottle,
      remainingPumps: item.remainingStockInPumps,
    };
    return mapping[columnId];
  };

  const getHeaderBackgroundColor = id => {
    switch (id) {
      case 'stockTake':
        return '#1565c0';

      case 'purchased':
        return '#1e88e5';

      case 'total':
        return '#303f9f';

      case 'used':
        return '#3f51b5';

      case 'remaining':
        return '#311b92';

      default:
        return '';
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2" component="span">
        Date:
      </Typography>{' '}
      <Typography variant="h6" component="span">
        {stockTakeDate ? moment(stockTakeDate).format('MMMM D, YYYY h:mm A') : ''}
      </Typography>
      <Typography variant="h3" style={{ marginTop: '24px', marginBottom: '24px' }}>
        Stock Take Details
      </Typography>
      <Table style={{ minHeight: '100px' }}>
        <TableHead>
          <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
            {headerColumns.map(column => (
              <TableCell
                key={column.id}
                align="center"
                className={clsx(classes.borderRight, classes.header)}
                style={{ minWidth: 150, backgroundColor: getHeaderBackgroundColor(column.id) }}
                colSpan={column.colSpan}
                rowSpan={column.rowSpan || 1}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
            {subHeaderColumns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                className={clsx(classes.borderRight, classes.header)}
                style={{
                  minWidth: column.id === 'item' ? 150 : 80,
                  backgroundColor: getHeaderBackgroundColor(column.parentId),
                }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stockTakeDetails.stockTake && stockTakeDetails.stockTake.length > 0 ? (
            stockTakeDetails.stockTake.map(item => (
              <TableRow key={item.inventoryID} className={classes.tableRowRoot}>
                <TableCell align="left" colSpan={2} style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                  {item.inventoryItemName}
                </TableCell>
                {subHeaderColumns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ borderRight: column.borderRight ? '1px solid rgba(224, 224, 224, 1)' : '' }}>
                    {column.id === 'remainingBottles' || column.id === 'remainingPumps' ? (
                      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {parseInt(
                          column.id === 'remainingBottles'
                            ? formatPercentage(item.remainingStockBottle, 3)
                            : item.remainingStockInPumps,
                        ) < 0 ? (
                          <Chip
                            label={
                              column.id === 'remainingBottles'
                                ? formatPercentage(item.remainingStockBottle, 3)
                                : item.remainingStockInPumps
                            }
                            style={{
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.common.white,
                            }}
                          />
                        ) : column.id === 'remainingBottles' ? (
                          formatPercentage(item.remainingStockBottle, 3)
                        ) : (
                          item.remainingStockInPumps
                        )}
                      </Box>
                    ) : (
                      getCellContent(item, column.id)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    height: '50px',
                  }}>
                  {loading ? <CircularProgress size={30} /> : <Typography variant="h4">No Stock Take found</Typography>}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default StockTakeInfo;
