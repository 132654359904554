import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Snackbar, Typography, useTheme } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { httpClient } from 'appUtility/Api';
import { formatPercentage } from '@jumbo/utils/commonHelper';
import { history } from 'redux/store';
import StockTakeDetailsDialog from '../StockTakeDetailsDialog';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  categoryBorder: {
    borderLeft: '1px solid grey',
  },
  noBorder: {
    border: 'none',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ViewStockTake = () => {
  const classes = useStyles();
  const location = useLocation();
  const [memberID, setMemberID] = useState(location.pathname.slice(23));
  const [stockTakeData, setStockTakeData] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [stockTakeID, setStockTakeID] = useState(''); // currently clicked details item stock take id
  const [stockTakeDate, setStockTakeDate] = useState('');
  const [openDetailsDialog, setOpenDetailsDialog] = useState(true);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(10);

  const theme = useTheme();

  const getStockTakeData = () => {
    setLoading(true);
    httpClient.get(`member/${memberID}/stocktake?page=${page}&per_page=${rowsPerPage}`).then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setStockTakeData(data.data);
        setPaginationData(data.meta);
      } else {
        setLoading(false);
        // setOpen(true);
        // setMessage(data.message);
        // setMessageState('error');
      }
    });
  };

  useEffect(() => {
    getStockTakeData();
  }, []);

  useEffect(() => {
    setMemberID(location.pathname.slice(23));
  }, [location]);

  const handleChangePage = (e, page) => {
    setLoading(true);
    httpClient.get(`member/${memberID}/stocktake?page=${page}&per_page=${rowsPerPage}`).then(({ data }) => {
      setLoading(false);
      setStockTakeData(data.data);
      setPaginationData(data.meta);
    });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    setLoading(true);
    httpClient.get(`member/${memberID}/stocktake?page=${1}&per_page=${+event.target.value}`).then(({ data }) => {
      setLoading(false);
      setStockTakeData(data.data);
      setPaginationData(data.meta);
    });
  };

  const handleBack = () => {
    history.push('/members/listMembers');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClickDetails = (stockTakeID, date) => {
    setStockTakeID(stockTakeID);
    setStockTakeDate(date);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setStockTakeID('');
  };

  const category4Data = stockTakeData && stockTakeData.filter(data => data.categoryID === 4);

  const renderTableHeaders = data => {
    return data[0]?.data.map(item => (
      <React.Fragment key={item.inventoryID}>
        <TableCell
          colSpan={2}
          className={item.inventoryName.includes('Herbal Deluxe Shampoo') ? classes.categoryBorder : ''}
          style={{ color: theme.palette.common.white, borderRight: '1px solid #e0e0e0' }}>
          <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
            {item.inventoryName}
          </Typography>
        </TableCell>
      </React.Fragment>
    ));
  };

  const renderTableSubHeaders = data => {
    return data[0]?.data.map(item => (
      <React.Fragment key={item.inventoryID}>
        <TableCell
          className={item.inventoryName.includes('Herbal Deluxe Shampoo') ? classes.categoryBorder : ''}
          style={{
            whiteSpace: 'nowrap',
            borderRight: '1px solid #e0e0e0',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          }}>
          Bottles
        </TableCell>
        <TableCell
          className={item.inventoryName.includes('Herbal Deluxe Shampoo') ? classes.categoryBorder : ''}
          style={{
            whiteSpace: 'nowrap',
            borderRight: '1px solid #e0e0e0',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          }}>
          % Left
        </TableCell>
      </React.Fragment>
    ));
  };

  const renderTableBody = () => {
    return Array.from({ length: category4Data.length }).map((_, index) => {
      const category4Item = category4Data[index] || { data: [] };
      return (
        <TableRow key={`row-${index}`}>
          <TableCell className={classes.categoryBorder} style={{ whiteSpace: 'nowrap' }}>
            {category4Item.createdAt ? moment(category4Item.createdAt).format('MMMM D, YYYY h:mm A') : ''}
          </TableCell>
          {category4Item.data.map(item => (
            <React.Fragment key={`cat4-${item.inventoryID}`}>
              <TableCell>{item.stockQuantity}</TableCell>
              <TableCell>{formatPercentage(item?.lastRemainingPercent)}</TableCell>
            </React.Fragment>
          ))}
          {category4Item.data.length === 0 &&
            Array(12)
              .fill()
              .map((_, i) => <TableCell key={`empty-cat4-${i}`}></TableCell>)}
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleClickDetails(category4Item.stockTakeID, category4Item.createdAt)}>
              <span style={{ whiteSpace: 'nowrap' }}>View details</span>
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  const itemslength = category4Data && category4Data.length > 0 && category4Data[0]?.data?.length;

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
        <h2>Stock Take</h2>
        <Button variant="contained" color="primary" onClick={handleBack}>
          <ArrowBackIcon fontSize="small" /> Back
        </Button>
      </Box>
      {category4Data && category4Data.length > 0 ? (
        <>
          <TableContainer component={Paper} style={{ marginBottom: '24px' }}>
            <Table className={classes.table} aria-label="stock table">
              <TableHead>
                <TableRow style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.common.white }}>
                  <TableCell
                    colSpan={itemslength * 2 + 2}
                    align="center"
                    className={classes.categoryBorder}
                    style={{ color: theme.palette.common.white }}>
                    Shampoo Order
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
                  {itemslength > 0 && (
                    <>
                      <TableCell
                        className={classes.categoryBorder}
                        style={{ borderRight: '1px solid #e0e0e0', color: theme.palette.common.white }}>
                        Created at
                      </TableCell>
                      {renderTableHeaders(category4Data)}
                      <TableCell
                        className={classes.categoryBorder}
                        style={{ borderRight: '1px solid #e0e0e0', color: theme.palette.common.white }}>
                        Actions
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
                  {itemslength > 0 && (
                    <>
                      <TableCell
                        className={classes.categoryBorder}
                        style={{ borderRight: '1px solid #e0e0e0', color: theme.palette.common.white }}></TableCell>
                      {renderTableSubHeaders(category4Data)}
                      <TableCell
                        className={classes.categoryBorder}
                        style={{ borderRight: '1px solid #e0e0e0', color: theme.palette.common.white }}></TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>{renderTableBody()}</TableBody>
              {/* <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={paginationData?.total && paginationData?.total}
                    SelectProps={{
                      native: true,
                    }}
                    labelDisplayedRows={() =>
                      `${paginationData?.from !== null ? paginationData?.from : '0'} - ${
                        paginationData?.to !== null ? paginationData?.to : '0'
                      } to ${paginationData?.total}`
                    }
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter> */}
            </Table>
          </TableContainer>
          <Typography variant="body2" display="block" sx={{ color: '#9e9e9e', textAlign: 'left', marginTop: '32px' }}>
            * Bottles - Total Quantity (in Bottles)
          </Typography>
          <Typography variant="body2" display="block" sx={{ color: '#9e9e9e', textAlign: 'left', marginTop: '32px' }}>
            ** % Left - Last Used Bottle Remaining Percentage
          </Typography>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '16px',
            paddingBottom: '16px',
          }}>
          {loading ? <CircularProgress size={30} /> : <Typography variant="h3">No Stock Take found</Typography>}
        </Box>
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>

      {openDetailsDialog && stockTakeID && (
        <StockTakeDetailsDialog
          open={openDetailsDialog}
          onClose={handleCloseDetailsDialog}
          memberID={memberID}
          stockTakeID={stockTakeID}
          stockTakeDate={stockTakeDate}
        />
      )}
    </>
  );
};

export default ViewStockTake;
